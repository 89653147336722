<template>
  <div class="md-layout user">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="admin">
        <md-card-header
          data-background-color="green"
          class="card-header card-header-avatar"
        >
          <div class="user-header">
            <div class="user-avatar">
              <img
                :src="admin.avatar || require('@/assets/img/new_logo.png')"
                alt="Аватар"
              />
            </div>
            <div class="user-info-header">
              <div class="user-info-name">User ID: {{ admin.id }}</div>
              <div class="user-info-date">
                Дата регистрации: {{ getDate(admin.created_at) }}
              </div>
              <div class="user-info-date">
                Товаров добавлено: {{ admin.product_create_count }}
              </div>
            </div>
          </div>
          <div class="user-header-buttons">
            <!-- <md-button
              class="md-raised md-primary"
              :disabled="admin.id == userId"
              @click="changePassword"
            >
              Сбросить пароль
            </md-button> -->
            <md-button
              class="md-raised md-danger"
              :disabled="admin.id == userId"
              @click="showModal = true"
            >
              Удалить пользователя
            </md-button>
          </div>
        </md-card-header>
        <md-card-content class="user-info md-layout">
          <md-field>
            <label>ФИО</label>
            <md-input v-model="admin.name" :disabled="true" />
          </md-field>

          <md-field>
            <label>Телефон</label>
            <md-input
              v-model="admin.phone"
              v-mask="'+# (###) ###-##-##'"
              md-counter="false"
              maxlength="18"
              :disabled="true"
            />
          </md-field>

          <md-field>
            <label>E-mail</label>
            <md-input v-model="admin.email" :disabled="true" />
          </md-field>

          <div class="md-layout-item md-large-size-10">
            <md-field>
              <label>Роль</label>
              <md-input v-model="admin.role" :disabled="true" />
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-10">
            <md-field>
              <label>Статус</label>
              <md-input
                :value="
                  admin.status == 'active' || admin.status == 1
                    ? 'Активен'
                    : 'Не активен'
                "
                :disabled="true"
              />
            </md-field>
          </div>

          <div
            class="md-layout-item md-large-size-100"
            style="text-align: right"
          >
            <md-button
              class="md-raised md-primary"
              @click="$router.push(`/admins/edit/${admin.id}`)"
            >
              Редактировать данные
            </md-button>
          </div>
        </md-card-content>
      </md-card>

      <AdminProductsTable
        :products="admin_products"
        @prev="goToPage('prev')"
        @next="goToPage('next')"
      />
    </div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить администратора?"
      @confirm="deleteAdmin"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import AdminProductsTable from "@/components/Tables/AdminProductsTable";
import ModalConfirm from "@/components/Modals/Confirm";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    AdminProductsTable,
    ModalConfirm,
  },

  data() {
    return {
      currentPage: 1,
      showModal: false,
    };
  },

  computed: {
    ...mapState({
      admin: (state) => state.admins.admin,
      admin_products: (state) => state.admins.admin_products,
      userId: (state) => state.login.userId,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getAdmin({ admin_id: +this.$route.params.id });
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  beforeDestroy() {
    this.getAdmin(null);
  },

  methods: {
    ...mapActions("admins", ["getAdmin", "removeAdmin", "resetPassword"]),

    // async changePassword() {
    //   this.$store.commit("SET_SHOW_LOADER", true);
    //   await this.resetPassword(this.admin.id);
    //   this.$store.commit("SET_SHOW_LOADER", false);
    // },

    async deleteAdmin() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeAdmin(this.admin.id);
      if (success) {
        this.showModal = false;
        this.$router.replace("/admins/list");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    async goToPage(direction) {
      direction == "prev" ? this.currentPage-- : this.currentPage++;

      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getAdmin({
        admin_id: +this.$route.params.id,
        page: this.currentPage,
      });
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-avatar {
  display: flex;
  justify-content: space-between;
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;

  .md-field {
    flex: 0 0 20%;
    margin: 10px 15px;
  }
}

.user-header {
  display: flex;

  &-buttons {
    display: flex;
    align-items: center;
  }

  .user-avatar {
    width: 80px;
    height: 80px;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-info-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>