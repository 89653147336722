<template>
  <md-card>
    <md-card-header data-background-color="blue" class="card-header">
      <div class="card-header-info">
        <h4 class="title">Список товаров</h4>
      </div>
    </md-card-header>
    <md-card-content>
      <div
        v-if="products && products.data && products.data.length"
        class="position-table"
      >
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <md-table v-model="products.data">
          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            @click="$router.push(`/products/${item.product.id}`)"
          >
            <md-table-cell md-label="ID" class="custom-field">
              <span>{{ item.product.id }}</span>
            </md-table-cell>

            <md-table-cell md-label="Название товара" class="custom-field">
              <span>{{ item.product.name }}</span>
            </md-table-cell>

            <md-table-cell md-label="Размер" class="custom-field">
              <span>{{ getSizes(item.product.options) }}</span>
            </md-table-cell>

            <md-table-cell md-label="Цвет" class="custom-field">
              <span>{{ getColors(item.product.options) }}</span>
            </md-table-cell>

            <md-table-cell md-label="Количество" class="custom-field">
              <span>{{ item.product.quantity }}</span>
            </md-table-cell>

            <md-table-cell md-label="Цена за шт." class="custom-field">
              <span>{{ item.product.price }}</span>
            </md-table-cell>

            <md-table-cell md-label="Дата создания" class="custom-field">
              <span>{{ formatDate(item.product.created_at) }}</span>
            </md-table-cell>

            <md-table-cell md-label="Активен" class="custom-field">
              <span>{{ item.product.status }}</span>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <div v-if="products.last_page > 1" class="pagination">
          <md-button
            class="md-primary"
            :disabled="products.current_page === 1"
            @click="$emit('prev')"
          >
            <md-icon>navigate_before</md-icon>
          </md-button>
          <div class="pages">
            <span>{{ products.current_page }}</span>
            /
            <span>{{ products.last_page }}</span>
          </div>
          <md-button
            class="md-primary"
            :disabled="products.current_page === products.last_page"
            @click="$emit('next')"
          >
            <md-icon>navigate_next</md-icon>
          </md-button>
        </div>
      </div>
      <div v-else class="my-empty empty">Пусто</div>
    </md-card-content>
  </md-card>
</template>

<script>
import { formatISODate } from "@/helpers/formatDate";

export default {
  props: {
    products: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatDate(date) {
      return formatISODate(date);
    },

    getColors(options) {
      let colors = options.filter((option) => option.attribute_id == 2);
      return colors
        .flat()
        .map((color) => color.name)
        .join(", ");
    },

    getSizes(options) {
      let sizes = options.filter((option) => option.attribute_id == 1);
      return sizes
        .flat()
        .map((size) => size.name)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-row {
    cursor: pointer;
  }
}
</style>